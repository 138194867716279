<template>
  <div>
    开发中......
<!--    <ul>-->
<!--      <li v-for="(tag,index) in tags" :key="index">-->
<!--        <router-link :to="{name:'home',query:{tag:tag.name}}">-->
<!--          {{tag.name}} {{tag.num}}-->
<!--        </router-link>-->
<!--      </li>-->
<!--    </ul>-->
  </div>
</template>

<script>
export default {
  name: "TagListPage",
  data() {
    return {
      tags:[],
    }
  },
  created() {
    this.$axios.get("/tags.json").then((resp) => {
      this.tags=resp.data;
    })
  }
}
</script>

<style scoped>

</style>
